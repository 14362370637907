export function filterDate (datestr) {
  var weekArray = new Array("周日", "周一", "周二", "周三", "周四", "周五", "周六");
  var week = weekArray[new Date(datestr).getDay()];
  return week;
}

export function formatPicture (url) {
  return url && url.indexOf('http') === -1 
    ? 'https://reservation.geeclo.com/ossfile/' + url
    : url 
}

export function formatPrice (val) {
  return (val / 100).toFixed(val % 10 ? 2 : 1)
}