export default {
  state: {
    id: '',
    avatar: '',
    mobile: '',
    nickname: '',
    department: '',
    token: '',
    payInfo: {
      appointmentOrder: '',
      appointmentTime: '',
      appointmentPeriod: ''
    },

    appointmentNumber: '',
    payResult: '',
    orderIdOrSN: '',
    scrollTop: 0
  },

  mutations: {
    setUserInfo (state, userInfo) {
      state.id = userInfo.id
      state.avatar = userInfo.head
      state.mobile = userInfo.mobile
      state.nickname = userInfo.nickName,
      state.token = userInfo.token
    },

    setDepartment (state, department) {
      state.department = department
    },

    setPayInfo (state, appointInfo) {
      state.payInfo.appointmentTime = appointInfo.visitTime
      state.payInfo.appointmentPeriod = appointInfo.timeLabel
    },

    setPayResult (state, payResult) {
      state.payResult = payResult
    },

    setOrderIdOrSN (state, orderIdOrSN) {
      state.orderIdOrSN = orderIdOrSN
    },

    HANDLE_SCROLL: (state, scrollTop) => {
      state.scrollTop = scrollTop
    },
  },

  actions: {
    initUser ({ commit }, userInfo) {
      commit('setUserInfo', userInfo)
    }
  }
}