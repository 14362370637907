import axios from 'axios'
import { Toast } from 'vant'

const service = axios.create({
  baseURL: 'https://reservation.geeclo.com/apricot_forest/restApi',
  timeout: 20000,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
  method: 'post'
})

axios.defaults.withCredentials = true
axios.defaults.headers.common['Content-Type'] = 'application/json'


service.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  const needToken = config.url.indexOf('/auth/') === -1
  
  if (token && needToken) {
    config.headers['Authorization'] = 'Bearer ' + token
  }

  config.data = JSON.parse(JSON.stringify(config.data) || '{}')

  if (config.data.restful === false) config.baseURL = config.baseURL.slice(0, -8)

  if (config.data.current) {
    config.data.pageNo = config.data.current
    delete config.data.current
  }

  if (config.data.limit) {
    config.data.pageSize = config.data.limit
    delete config.data.limit
  }

  return config
}), (error => Promise.reject(error))

service.interceptors.response.use(res => res.data, error => {
  if (error.response.status === 400) {
    Toast(error.response.data.message)
  } else if (error.response.status === 401) {
    localStorage.removeItem('token')
    Toast.fail('登录过期，请重新刷新页面')
    window.location.reload()
  } else {
    Toast.clear()
  }

  return Promise.reject(error.response)
})

export default service
