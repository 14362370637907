import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [
    // 主页
    { path: '', name: 'home', meta: { title: '选择科室' }, component: () => import('@/views/home/index') },

    // 选择医生
    { path: '/doctor/:id', name: 'doctor', meta: { title: '选择医生' }, component: () => import('@/views/doctor/list') },
    { path: '/doctor-detail/:id', name: 'doctor-detail', meta: { title: '医生详情' }, component: () => import('@/views/doctor/detail') },

    // 预约日期
    { path: '/date-selection/:id', name: 'date-selection', meta: { title: '选择日期' }, component: () => import('@/views/date-selection/index') },

    // 预约记录
    { path: '/appointment-record', name: 'appointment-record', meta: { title: '预约记录' }, component: () => import('@/views/appointment-record/index') },

    // 支付/预约结果
    { path: '/payment-results', name: 'payment-results', meta: { title: '提交预约' }, component: () => import('@/views/payment-results/index') },

    // 支付中间页
    { path: '/pay-page', name: 'pay-page', meta: { title: '提交预约' }, component: () => import('@/views/pay-page/index') },
  ]
})

