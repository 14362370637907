<template>
  <div id="app">
    <template v-if="token">
      <vue-page-stack>
        <router-view></router-view>
      </vue-page-stack>
    </template>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      token: ''
    }
  },
  created() {
    
    this.token = localStorage.getItem('token')

    //在页面加载时读取localStorage里的状态信息
    if(localStorage.getItem("store")) {
      // console.log(localStorage.getItem("store"))
      this.$store.replaceState(JSON.parse(localStorage.getItem("store")));
    }

    
    //在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener('pagehide', ()=>{
      // 没有token状态下，刷新页面会清空VueX里的数据，有token就不清空
      if(!this.token) {
        localStorage.removeItem('store')
        return
      }

      localStorage.setItem("store",JSON.stringify(this.$store.state))
    })
    
  },

  mounted() {
    window.addEventListener('scroll', () => {
      this.$store.commit('HANDLE_SCROLL', window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop)
    })
  },
}
</script>

<style lang="scss" scoped>
  #app {
    min-height: 100vh;
    background: #F5F5F5;
    color: #333;
    font-size: .24rem;
  }

  body {
    padding: 0;
    margin: 0;
  }
</style>
