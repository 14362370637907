import { getUrlKeys } from '@/utils/index'
import request from '@/utils/service'
import store from './store'

// 如果有 token就拿 token 登录、没有则拿 token 去授权
const token = localStorage.getItem('token')

if (token) {
  request({ url: '/member/info' }).then(userRes => {
    store.dispatch('initUser', userRes.result)
  })
} else {
  const env = process.env.NODE_ENV
  const code = getUrlKeys('code')

  if (!code) {
    if (env === 'development') {
      window.location.replace(window.location.origin + window.location.pathname + '?code=kunsect')
    } else {
      window.location.replace('https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx556055e319bba389&redirect_uri='
        + encodeURIComponent(window.location.origin + window.location.pathname)
        + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect')
    }
  } else {
    request({ url: '/paltform/wx/mp/auth/authLogin', data: { appid: 'wx556055e319bba389', code: code }}).then(userRes => {
      localStorage.setItem('token', userRes.result.token)
      store.dispatch('initUser', userRes.result.memberDetail)
      window.location.replace(window.location.origin + window.location.pathname)
    }).catch( () => {
      window.location.replace(window.location.origin + window.location.pathname)
    })
  }
}