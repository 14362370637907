import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'vant/lib/index.css'
import './permission'
import request from '@/utils/service'
import store from './store'
import '@/styles/index.scss'

Vue.prototype.$request = request

Vue.prototype.$store = store

import { Button, Popup, Field, Loading } from 'vant'

Vue.use(Button)
Vue.use(Popup)
Vue.use(Field)
Vue.use(Loading)

Vue.config.productionTip = false

import * as filters from './filters'

// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// 路由导航缓存
import VuePageStack from 'vue-page-stack'

Vue.use(VuePageStack, { router })

// import vconsole from 'vconsole'
// new vconsole()

router.beforeEach((to, from, next) => {
  if (to.meta.title) document.title = to.meta.title

  return next()
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
